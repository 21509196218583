import * as React from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap-reboot.min.css"
import "./layout.css"
import Header from "./header.js"
import styled from "@emotion/styled"
import Footer from "./footer"
import MobileFooter from "./mobile-footer"

const Layout = ({ children }) => {
  return (
    <div
      css={{
        width: "100%",
        marginBottom: 50,
        "@media(min-width: 720px)": {
          marginBottom: 0,
        },
      }}
    >
      <Header />
      <div
        style={{
          paddingTop: 65,
          minHeight: "100vh",
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  )
}

export const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 30px 15px;
  @media (min-width: 720px) {
    padding: 30px;
  }
`

export const Section = styled.section`
  padding: 0 15px;
  margin: 30px 0;
  max-width: 1170px;
  @media (min-width: 720px) {
    padding: 0 30px;
    margin: 100px auto;
  }
`

export const FluidSection = styled.section`
  margin: 30px auto;
  @media (min-width: 720px) {
    margin: 100px auto;
  }
`

export const BarTitle = styled.h2`
  font-size: 14px;
  display: grid;
  grid-template-columns: minmax(min-content, 100px) max-content minmax(min-content, 100px);
  justify-content: center;
  column-gap: 10px;
  padding: 0 15px;
  margin-bottom: 30px;
  align-items: center;
  &:before {
    content: ' ';
    background-color: #ccc;
    height: 1px;
    width: auto;
  }
  &:after{
    content: ' ';
    background-color: #ccc;
    height: 1px;
    width: auto;
  }
  @media(min-width: 720px) {
    margin-bottom: 100px;
    font-size: 25px;
  };
}
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
