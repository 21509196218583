import React from "react"
import line from "../images/footer-line.svg"
import phone from "../images/footer-phone.svg"
import form from "../images/footer-form.svg"
import { css } from "@emotion/react"
import Avatar from "../images/contact-avatar.gif"

const linkStyle = css({
  display: "flex",
  alignItems: "center",
  gap: 10,
  color: "inherit",
  borderRight: "1px solid #cbcbcb",
  paddingLeft: 10,
  lineHeight: 1.2,
  "&:last-child": {
    borderWidth: 0,
  },
  "&:hover": {
    color: "inherit",
  },
  "@media(min-width: 768px)": {
    borderRightWidth: 0,
    flexDirection: "column",
    gap: 8,
    marginBottom: 16,
    padding: 0,
    borderBottom: "1px solid #cbcbcb",
    paddingBottom: 10,
  },
})

const imgIcon = css({
  width: 24,
  height: 24,
  "@media(min-width: 768px)": {
    width: 32,
    height: 32,
  },
})
export default function MobileFooter() {
  return (
    <div
      css={{
        backgroundColor: "#f2f2f2",
        opacity: 0.9,
        position: "fixed",
        bottom: 0,
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        padding: 15,
        width: "100%",
        zIndex: 2,
        "@media(min-width: 768px)": {
          gridTemplateColumns: "repeat(1, 1fr)",
          padding: 8,
          paddingTop: 60,
          width: "auto",
          right: "1rem",
          bottom: "1rem",
          borderRadius: 30,
        },
      }}
    >
      <img
        src={Avatar}
        css={{
          position: "absolute",
          bottom: "112%",
          right: -20,
          width: 153,
          maxWidth: 153,
          "@media(min-width: 768px)": {
            top: "-28%",
          },
        }}
      />
      <a css={linkStyle} href={"https://line.me/R/ti/p/%40lotusclinic168"}>
        <img src={line} css={imgIcon} />
        聯絡我們
      </a>
      <a css={linkStyle} href={"tel:+886800599666"}>
        <img src={phone} css={imgIcon} />
        客服專線
      </a>
      <a href={"#main-contact-form"} css={linkStyle}>
        <img src={form} css={imgIcon} />
        表單諮詢
      </a>
    </div>
  )
}
