import useInView from "react-cool-inview"
import { config, useSpring } from "@react-spring/web"
export function useTextTranslateEffect() {
  const { observe, inView } = useInView({
    threshold: 0.5,
    unobserveOnEnter: true,
  })

  const styles = useSpring({
    from: { transform: `translateY(${inView ? 125 : 0}%)` },
    to: { transform: `translateY(${inView ? 0 : 125}%)` },
    config: config.molasses,
  })

  return { observe, styles, inView }
}
