import React, { useEffect } from "react"
import styled from "@emotion/styled"

export const Input = styled.input`
  box-sizing: content-box;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #d5dadd;
  outline: none;
  padding: 0 13px;
  background-color: #f9f9f9;

  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #93dcff;
  }
`

export const TextArea = styled.textarea`
  box-sizing: content-box;
  height: 105px;
  border-radius: 2px;
  border: 1px solid #d5dadd;
  outline: none;
  padding: 0 13px;
  background-color: #f9f9f9;

  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #93dcff;
  }
`
export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

export default function Form() {
  const [href, setHref] = React.useState("")
  useEffect(() => {
    setHref(window?.location?.href)
  }, [])
  return (
    <form
      id={"main-contact-form"}
      name={"contact"}
      method={"POST"}
      data-netlify={"true"}
      action={"/form-success"}
      css={{
        border: "1px solid #cb9f45",
        padding: 15,
        "@media(min-width: 768px)": {
          padding: 30,
        },
      }}
    >
      <h2
        css={{
          borderBottom: "1px solid #666",
          paddingBottom: 5,
          marginBottom: 10,
        }}
      >
        線上預約
      </h2>
      <input
        type={"hidden"}
        value={"菡生美無極官網聯絡表單"}
        name={"subject"}
        id={"subject"}
      />

      <input type="hidden" name="form-name" value="contact" />
      <input type={"hidden"} name="來源網址" value={href} />
      <FormItem>
        <label htmlFor={"username"}>姓名</label>
        <Input id={"username"} name={"username"} />
      </FormItem>
      <FormItem>
        <label htmlFor={"email"}>E-mail</label>
        <Input type={"email"} id={"email"} name={"email"} required />
      </FormItem>
      <FormItem>
        <label htmlFor={"contact-number"}>聯絡電話</label>
        <Input id={"contact-number"} name={"contact-number"} required />
      </FormItem>
      <FormItem>
        <label htmlFor={"line-id"}>Line/Wechat</label>
        <Input id={"line-id"} name={"line-id"} required />
      </FormItem>
      <FormItem>
        <label htmlFor={"question"}>諮詢項目</label>
        <Input id={"question"} name={"question"} />
      </FormItem>
      <FormItem>
        <label htmlFor={"memo"}>備註</label>
        <TextArea id={"memo"} name={"memo"} />
      </FormItem>
      <button
        type="submit"
        css={{
          lineHeight: "35px",
          backgroundColor: "transparent",
          border: "1px solid #666",
          borderRadius: 3,
          padding: "15px 0",
          width: 300,
          float: "right",
          "&:hover": {
            backgroundColor: "#cb9f45",
            color: "#fff",
          },
        }}
      >
        送出
      </button>
      <div css={{ clear: "both" }} />
    </form>
  )
}
